<template>
    <div class="hello">
        <div class="loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
<script>
import liff from "@line/liff";
import axios from "axios";

export default {
    name: "liff-app",
    data() {
        return {
            botBasicId: "",
            thirdPartyURLParams: process.env.VUE_APP_THIRD_PARTY_URL_PARAMS.split(","),
        };
    },
    async mounted() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const { liffId, token, datafeed, botId, fingerprint } = params;

        await liff.init({ liffId });
        this.botBasicId = botId;

        if (!liff.isLoggedIn()) {
            let uri = `${process.env.VUE_APP_LIFF_ENDPOINT}?liffId=${liffId}&botId=${this.botBasicId}&datafeed=${datafeed}&fingerprint=${fingerprint}&token=${token}`;
            liff.login({ redirectUri: uri });
        } else {
            const { userId } = await liff.getProfile();

            if (userId && token) {
                const formData = this.constructPayload(params, userId, token, fingerprint, datafeed);

                try {
                    await axios({
                        method: "POST",
                        url:
                            process.env.VUE_APP_API_ENDPOINT +
                            "userSegmentRegisterViaLiff",
                        data: formData,
                    });
                    if (this.botBasicId) {
                        location.href = `https://line.me/ti/p/${this.botBasicId}`;
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                liff.closeWindow();
            }
        }
    },
    methods: {
        constructPayload(params, userId, token, fingerprint, datafeed) {
            let payload = {
                token: token,
                is_datafeed: datafeed === "true",
                userId: userId,
                fingerprint: fingerprint,
                third_party_socket: {},
            };

            this.thirdPartyURLParams.forEach(param => {
                const paramValue = params[param];
                if (paramValue) {
                    payload["third_party_socket"][param] = paramValue;
                }
            });

            return payload;
        }
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    display: flex;
    justify-content: space-between;
    width: 60px;
}

.loader div {
    width: 15px;
    height: 15px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.6);
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.6s;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
</style>
